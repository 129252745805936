import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdvancedSettings } from '../types/types';

const initialState: AdvancedSettings = {
  autoPrefix: true,
  implicitMoreExpansion: true,
  customPrefix: '',
  limit: 50,
  approximateSearch: true,
  efSearch: 2000,
  lexicalRRF: false,
  lexicalRRFAlpha: 0.5,
  searchType: 'semantic',
  maxLiveRecommendationsHistory: 10,
};

export const advancedSettingsSlice = createSlice({
  name: 'advancedSettings',
  initialState,
  reducers: {
    setAutoPrefix: (state, action: PayloadAction<boolean>) => {
      state.autoPrefix = action.payload;
    },
    setImplicitMoreExpansion: (state, action: PayloadAction<boolean>) => {
      state.implicitMoreExpansion = action.payload;
    },
    setCustomPrefix: (state, action: PayloadAction<string>) => {
      state.customPrefix = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setApproximateSearch: (state, action: PayloadAction<boolean>) => {
      state.approximateSearch = action.payload;
    },
    setEfSearch: (state, action: PayloadAction<number>) => {
      state.efSearch = action.payload;
    },
    setSearchType: (state, action: PayloadAction<'semantic' | 'lexical'>) => {
      state.searchType = action.payload;
    },
    setLexicalRRF: (state, action: PayloadAction<boolean>) => {
      state.lexicalRRF = action.payload;
    },
    setLexicalRRFAlpha: (state, action: PayloadAction<number>) => {
      state.lexicalRRFAlpha = action.payload;
    },
    resetAdvancedSettings: (state) => {
      state = initialState;
    },
  },
});

export const {
  setAutoPrefix,
  setCustomPrefix,
  setLimit,
  resetAdvancedSettings,
  setImplicitMoreExpansion,
  setApproximateSearch,
  setEfSearch,
  setSearchType,
  setLexicalRRF,
  setLexicalRRFAlpha,
} = advancedSettingsSlice.actions;

export default advancedSettingsSlice.reducer;
