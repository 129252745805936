export interface Product {
  id: string;
  name: string;
  description?: string;
  price?: number;
}

export interface ImageProduct extends Product {
  image_url: string;
}

export interface TextProduct extends Product {
  description: string;
  display_image: string;
}

export type AnyProduct = ImageProduct | TextProduct;

export enum indexTypeEnum {
  MULTIMODAL_IMAGE_TEXT = 'MULTIMODAL_IMAGE_TEXT',
  TEXT = 'TEXT',
  DEFAULT = 'DEFAULT',
}

export interface SearchSettings {
  queryWeight: number;
  posQueryWeight: number;
  negQueryWeight: number;
  customInstructionsWeight: number;
  totalFavouriteWeight: number;
}

export interface SearchRequest {
  query: string;
  moreOf: string | null;
  lessOf: string | null;
  filters: Filter[] | null;
  scoreModifiers: ScoreModifier[] | null;
  customInstructions: string | null;
  favourites: AnyProduct[];
  searchSettings: SearchSettings;
  demoID: string;
  style: string | null;
  advancedSettings: AdvancedSettings;
}

export interface SimilarRecommendationsRequest {
  product: Product;
  demoID: string;
  limit: number;
  diversity: number;
}

export interface CompletementaryRecommendationsRequest {
  product: Product;
  terms: string;
  demoID: string;
  limit: number;
  advancedSettings: AdvancedSettings;
}

export interface LiveRecommendationsRequest {
  itemIDs: string[];
  demoID: string;
  limit: number;
  maxItems: number;
}

export interface CustomInstructions {
  instructions: string | null;
}

export interface FilterSettings {
  [key: string]: {
    type: 'selector';
    options: string[];
  };
}

export interface DemoSettings {
  showMoreOf: boolean;
  showLessOf: boolean;
  showCustomInstructions: boolean;
  showFavourites: boolean;
  showSearchSettings: boolean;
  showStyle: boolean;
  showWebsiteSearchExtractor: boolean;
  availableStyles: QueryStyles;
  indexType: indexTypeEnum;
  deduplication?: boolean;
  defaultSearchType?: string;
  filterableAttributes?: string[];
  filterConfig?: FilterSettings;
  scoreModifierAttributes?: string[];
}

export interface AdvancedSettings {
  autoPrefix: boolean;
  implicitMoreExpansion: boolean;
  customPrefix: string;
  limit: number;
  approximateSearch: boolean;
  efSearch: number;
  lexicalRRF: boolean;
  lexicalRRFAlpha: number;
  searchType: 'semantic' | 'lexical';
  maxLiveRecommendationsHistory: number;
}

export interface RecallEvaluationRequest {
  queries: string[];
  efSearch: number;
  limit: number;
  demoID: string;
}

export interface CustomFilterRequest {
  name: string;
  imageURL: string;
}

export interface CustomFilter {
  name: string;
  customFilterTemplate: string;
}

export interface WebsiteExtractionSearchRequest {
  url: string;
  demoID: string;
}

export interface WebsiteExtractionSearchResponse {
  step: string;
  nQueries: number;
  nQueriesCompleted: number;
  queries: string[];
  results: Product[];
  error: boolean;
}

export interface QueryRecall {
  query: string;
  recall: number;
}

export interface RecallEvaluationResults {
  recalls: QueryRecall[];
  meanRecall: number;
  medianRecall: number;
  minRecall: number;
  maxRecall: number;
  k: number;
}

export interface Filter {
  attribute: string;
  comparitor: string;
  value: string;
}

export interface ScoreModifier {
  attribute: string;
  modifier: 'add_to_score' | 'multiply_score_by';
  value: number;
}

type QueryStyleOption = {
  value: string | null;
  label: string;
};

export type QueryStyles = QueryStyleOption[];

export type CartCompletionRequest = {
  demoID: string;
  products: AnyProduct[];
  searchSettings: SearchSettings;
  advancedSettings: AdvancedSettings;
};

export type CartCompletionResponse = {
  results: AnyProduct[];
  queries: string[];
  text: string;
};
